.App {
	background: #f2f2f2;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
}

#SplashLogo {
	/* height: 40vmin; */
	pointer-events: none;
	height: auto;
	width: 50%;
	position: relative;
}

.e {
	fill: #262533;
}

g.mules {
	position: relative;
	transform: translateX(200px);
}

.st1.mule {
	fill: transparent;
	stroke: #262533;
	stroke-dasharray: 1200;
	stroke-dashoffset: 1200;
	animation: dash 2.5s ease-in-out forwards;
}

.notice {
	opacity: 0;
	animation: fadeIn 2.5s ease 0.6s forwards;
}

.st1.letter {
	fill: transparent;
	stroke-width: 2px;
	stroke: #262533;
	stroke-dasharray: 1200;
	stroke-dashoffset: 1200;
	animation: text-outline 2.5s ease 0.3s forwards;
	opacity: 0;
}

@keyframes dash {
	0% {
		stroke-dashoffset: 1200;
		stroke-width: 1;
	}
	50% {
		stroke-dashoffset: 0;
		stroke-width: 2;
	}
	100% {
		stroke: transparent;
		fill: #262533;
	}
}

@keyframes text-outline {
	0% {
		stroke-dashoffset: 1200;
		opacity: 0;
	}

	50% {
		stroke-dashoffset: 0;
		stroke: #262533;
	}

	100% {
		fill: #262533;
		opacity: 1;
		stroke: transparent;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
